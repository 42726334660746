import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const inspectorFindingsPlugin = createPlugin({
  id: 'inspector-findings',
  routes: {
    root: rootRouteRef,
  },
});

export const InspectorFindingsPage = inspectorFindingsPlugin.provide(
  createRoutableExtension({
    name: 'InspectorFindingsPage',
    component: () =>
      import('./components/InspectorFindingsPage').then(m => m.InspectorFindingsPage),
    mountPoint: rootRouteRef,
  }),
);
